import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import React, { type FormEvent, useCallback, useEffect, useState } from 'react'
import paymentsService from '../../services/paymentsService'
import { toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage/ToastMessage'

const MyBalanceKyc: React.FC = (
  moipPaymentAccount: any
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isAffiliationThreshold, setIsAffiliationThreshold] = useState<boolean>(false)

  useEffect(() => {
    setIsAffiliationThreshold(Boolean(moipPaymentAccount?.kycLinkCreationCount === 99))
  }, [])

  const checkKycStatus = useCallback(async () => {
    try {
      const { moipPaymentAccount } = await paymentsService.getPaymentAccount()
      return Boolean(moipPaymentAccount?.status === 'active')
    } catch (error) {
      console.error('Error checking KYC status:', error)
      return false
    }
  }, [])

  const pollKycStatus = useCallback(async () => {
    const startTime = Date.now()
    const timeoutDuration = 10 * 60 * 1000 // 10 minutes

    while (Date.now() - startTime < timeoutDuration) {
      const isApproved = await checkKycStatus()
      if (isApproved) {
        toast.success(<ToastMessage label="Identidade aprovada com sucesso!"/>)
        return
      }
      await new Promise(resolve => setTimeout(resolve, 5000))
    }

    toast.error(
      <ToastMessage label="Tempo limite excedido para aprovação da Identidade. Tente novamente mais tarde."/>
    )
  }, [checkKycStatus])

  const handleKYC = async (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      const kyc = await paymentsService.createKYCLink()

      if (kyc?.url) {
        // Open in new tab
        setTimeout(() => {
          window.open(kyc.url, '_blank', 'noopener,noreferrer')
        })
        // Start polling
        void pollKycStatus()
      } else {
        throw new Error('No KYC URL received')
      }
    } catch (e) {
      const { isWarn, message } = e as any
      isWarn
        ? toast.warn(<ToastMessage label={message}/>)
        : toast.error(<ToastMessage/>)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form className="bankAccount-kyc">
      <h2>
        ￼Precisamos confirmar sua identidade!
      </h2>
      <p>
        Durante o processo, você precisará tirar uma selfie com <strong>seu documento</strong>.
        <br/><br/>
        É rápido e garante a segurança de suas informações!
      </p>
      <Button
        variant="primary"
        onClick={isAffiliationThreshold
          ? () => window.open('https://qiper.zendesk.com/hc/pt-br/requests/new', '_blank')
          : handleKYC}
        isLoading={isLoading}
      >
        Confirmar Identidade
      </Button>
    </form>
  )
}

export default MyBalanceKyc
