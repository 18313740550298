import React, { type FormEvent, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import Navbar from '../../../components/Navbar/Navbar'
import ListingsProvider, { initialFilter } from '../../../components/ListingsProvider/ListingsProvider'
import FormField from '../../../components/FormComponents/FormField'
import TextField from '../../../components/FormComponents/TextField'
import { GlobalContext } from '../../../context/GlobalContext'
import { type UserProfileForm } from '../../../models/User'

import userService from '../../../services/userService'

import './ProfileUpdate.scss'
import { toast } from 'react-toastify'
import ToastMessage from '../../../components/ToastMessage/ToastMessage'
import CleaveField from '../../../components/FormComponents/CleaveField'
import Cta from '../../../components/Cta/Cta'

import { ButtonV2 as Button } from '../../../components/Button/ButtonV2'
import ReturnHeader from '../../../components/ReturnHeader/ReturnHeader'
import shipmentService from '../../../services/shipmentService'
import moment from 'moment/moment'
import { SelectSports } from '../../../components/SelectSports/SelectSports'
import SelectClubs from '../../../components/SelectClubs/SelectClubs'
import { social } from '../SocialDictionary'
import { SelectStateCity } from '../../../components/SelectStateCity/SelectStateCity'
import { type Options } from 'react-select'
import { atLeastTwoWords, cpfValidation } from '../../../helpers/yupValidation'
import { strictPhoneNumberRegex } from '../../../helpers/regex'
import { formatPhoneNumber } from '../../../helpers/formater'
import ProfileAvatar from '../ProfileContainer/ProfileAvatar'
import type { Image } from '../../../components/UploadImage/UploadImage'

interface SelectOptionProps {
  label: string
  value: string
}

const userSchema = Yup.object().shape(
  {
    photoURL: Yup.string().required('Campo obrigatório'),
    displayName: Yup.string().required('Campo obrigatório').test('Nome inválido', 'Nome deve conter pelo menos duas palavras', atLeastTwoWords),
    phoneNumber: Yup.string().matches(strictPhoneNumberRegex, 'Número de telefone inválido').required('Campo obrigatório'),
    birthDate: Yup.string().required('Campo obrigatório'),
    username: Yup.string().required('Campo obrigatório'),
    taxNumber: Yup.string().required('Campo obrigatório').test('cpf-validation', 'CPF inválido', cpfValidation),
    address: Yup.string().required('Campo obrigatório'),
    number: Yup.string().required('Campo obrigatório'),
    zipcode: Yup.string().required('Campo obrigatório'),
    district: Yup.string().required('Campo obrigatório'),
    currentState: Yup.string().required('Campo obrigatório'),
    currentCity: Yup.string().required('Campo obrigatório'),
    currentSportsOption: Yup.array().min(1, 'Campo obrigatório')
  })

const ProfileUpdate: React.FC = () => {
  const { userProfile, setUserProfile } = useContext(GlobalContext)
  const [user, setUser] = useState<UserProfileForm | null>(null)
  const [errors, setErrors] = useState<any>(null)
  const [isCheckout, setIsCheckout] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const currentState = userProfile?.address?.currentState || ''
  const currentCity = userProfile?.address?.currentCity || ''
  const currentCountry = 'BRA'
  const formRef = useRef<any>(null)
  const location = useLocation()

  const getCEPDetails = async (name: string, zipcode: string) => {
    if (!zipcode || zipcode.length !== 9) {
      return false
    }
    try {
      setIsLoading(true)
      const { state, city, neighborhood, street } = await shipmentService.getCEP({
        cepTo: zipcode
      })

      const cities: Options<SelectOptionProps> = [{ value: city, label: city }]
      const states: Options<SelectOptionProps> = [{ value: state, label: state }]

      setUser({
        ...user,
        zipcode,
        currentStateOption: states[0],
        currentCityOption: cities[0],
        country: 'BRA',
        district: neighborhood,
        number: '',
        address: street,
        address2: ''
      })
    } catch (err) {
      // TODO handle error properly
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const prepareDate = (dateString: string) => {
    const [day, month, year] = dateString.split('/')
    const formattedDateString = `${month}/${day}/${year}`
    const date = new Date(formattedDateString)
    return date.toString()
  }

  const handleStateChange = (option: any) => {
    setUser({
      ...user,
      currentStateOption: option,
      currentCityOption: undefined
    })
  }

  const handleCityChange = (option: any) => {
    setUser({
      ...user,
      currentCityOption: option
    })
  }

  const handleChangeInput = (name: string, value: string) => {
    setUser({
      ...user,
      [name]: value
    })
  }

  const handleChangeAvatar = async (image: Image) => {
    if (image.src) {
      setUser({
        ...user,
        photoURL: image.src
      })
    }
  }

  const handleChangeSocial = ({ socialName, url = '' }: { socialName: string, url: string }) => {
    const socialsNewtwork = user?.socialsNetwork || []
    if (socialsNewtwork.length === 0) socialsNewtwork.push({ url, title: socialName })
    const index = socialsNewtwork.findIndex(({ title }) => title === socialName)
    if (index === -1) {
      if (url.includes('http://') || url.includes('https://')) {
        url.replace('http://' || 'https://', '')
      }
      socialsNewtwork.push({ url, title: socialName })
    } else {
      socialsNewtwork[index].url = url
    }
    setUser({ ...user, socialsNetwork: socialsNewtwork })
  }

  const handleChangeSports = (option: any) => {
    setUser({
      ...user,
      currentSportsOption: option || []
    })
    setErrors({ ...errors, currentSportsOption: undefined })
  }

  const handleBtnCancel = () => {
    history.goBack()
  }

  const navigateToNextScreen = (username: string) => {
    const searchParams = new URLSearchParams(location.search)
    const nextScreen = searchParams.get('nextScreen')
    if (nextScreen === 'newEqipo') {
      history.replace('/eqipo/novo/')
    } else if (nextScreen === 'checkout') {
      history.replace('/checkout/')
    } else if (nextScreen === 'meuExtrato') {
      history.replace('/meu-extrato/')
    } else {
      history.replace('/atleta/' + username)
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    setErrors(null)

    const taxNumberOnlyDigits = user!.taxNumber?.replace(/[^\d]+/g, '')

    try {
      const payload = {
        ...user,
        taxNumber: taxNumberOnlyDigits,
        currentCity: user!.currentCityOption?.value,
        currentState: user!.currentStateOption?.value,
        socialsNetwork: user!.socialsNetwork ? user!.socialsNetwork.filter(({ url }) => url !== '') : [],
        sports: user!.currentSportsOption,
        clubs: user!.currentClubsOption
      }

      await userSchema.validate(payload, { abortEarly: false })

      const names = payload.displayName!.split(' ')
      const firstName = names[0]
      const lastName = names[names.length - 1]
      const birthDate = payload.birthDate ? prepareDate(payload.birthDate) : undefined

      try {
        const updatedUserProfile = await userService.updateUserProfile(
          {
            ...payload,
            firstName,
            lastName,
            birthDate,
            sports: user!.currentSportsOption!,
            address: {
              address: user!.address,
              address2: user!.address2,
              addressNumber: user!.number,
              district: user!.district,
              zipcode: user!.zipcode,
              currentCity: user!.currentCityOption!.value,
              currentState: user!.currentStateOption!.value,
              country: user!.country
            }
          })
        setUserProfile(updatedUserProfile)
        navigateToNextScreen(updatedUserProfile.username)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const formattedErrors: any = {}

        error.inner.forEach(
          (errorMessage: any) =>
            (formattedErrors[errorMessage.path] = errorMessage.message)
        )

        setErrors(formattedErrors)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsCheckout(location.pathname.includes('checkout'))
  }, [location])

  useEffect(() => {
    setUser({
      ...userProfile,
      birthDate: userProfile!.birthDate ? moment(userProfile!.birthDate).format('DD/MM/YYYY') : '',
      phoneNumber: formatPhoneNumber(userProfile!.phoneNumber!.slice(3, userProfile!.phoneNumber?.length)),
      address: userProfile!.address?.address || '',
      address2: userProfile!.address?.address2 || '',
      number: userProfile!.address?.number || '',
      district: userProfile!.address?.district || '',
      zipcode: userProfile!.address?.zipcode || '',
      currentStateOption: { value: currentState, label: currentState },
      currentCityOption: { value: currentCity, label: currentCity },
      currentSportsOption: userProfile!.sports,
      currentClubsOption: userProfile!.clubs,
      country: currentCountry
    })
  }, [userProfile, currentState, currentCity])

  useEffect(() => {
    if (errors) {
      const inputs = Object.values(formRef.current?.elements)
      const input: any = inputs.find((input: any) => Object.keys(errors).find(error => error === input.id))
      if (input) {
        input.focus()
      }
    }
  }, [errors])

  return (
    <div className="profile-edit">
      <ListingsProvider filter={initialFilter}>
        <Navbar/>
      </ListingsProvider>
      <ReturnHeader title="EDITAR PERFIL" border/>
      <form ref={formRef} className="profile-form-edit" onSubmit={handleSubmit} noValidate>
        {isCheckout && (
          <Cta
            notSpace
            title="COMPLETE SEUS DADOS"
            content={
              <>
                Precisamos de mais informações antes de comprar ou vender um
                equipo.
              </>
            }
          />
        )}
        <h5>DADOS PESSOAIS</h5>
        <div>
          <FormField
            name="photoURL"
            label="ALTERAR FOTO DE PERFIL"
            validation={errors?.photoURL}
            className='photoURL'
            required
          >
            <ProfileAvatar
              shared={false}
              isOwner={true}
              profile={userProfile || {}}
              handleChangeAvatar={handleChangeAvatar}
            />
          </FormField>
          <FormField
            name="displayName"
            label="Nome completo"
            validation={errors?.displayName}
            required
          >
            <TextField
              name="displayName"
              type="text"
              value={user?.displayName}
              onChange={handleChangeInput}
              placeholder="Digite seu nome completo"
              disabled={isLoading}
            />
          </FormField>
          <FormField
            name="birthDate"
            label="Data de nascimento"
            validation={errors?.birthDate}
            required
          >
            <CleaveField
              name="birthDate"
              inputMode="numeric"
              maxLength={10}
              options={{
                date: true,
                datePattern: ['d', 'm', 'Y']
              }}
              value={user?.birthDate}
              onChange={handleChangeInput}
              placeholder="DD/MM/AAAA"
              disabled={isLoading}
            />
          </FormField>
          <FormField
            name="phoneNumber"
            label="Telefone"
            validation={errors?.phoneNumber}
            required
          >
            <CleaveField
              name="phoneNumber"
              type="tel"
              inputMode="numeric"
              maxLength={14}
              options={{
                delimiters: ['(', ')', '-'],
                blocks: [0, 2, 5, 4],
                numericOnly: true
              }}
              value={user?.phoneNumber}
              onChange={handleChangeInput}
              placeholder="(XX)XXXX-XXXX"
              disabled={isLoading}
            />
          </FormField>
          <FormField
            name="taxNumber"
            label="CPF"
            validation={errors?.taxNumber}
            required
          >
            <CleaveField
              name="taxNumber"
              type="text"
              inputMode="numeric"
              maxLength={14}
              options={{
                delimiters: ['.', '.', '-'],
                blocks: [3, 3, 3, 2],
                uppercase: true
              }}
              value={user?.taxNumber}
              onChange={handleChangeInput}
              placeholder="###.###.###-##"
              disabled={isLoading}
            />
          </FormField>
          <h5>ENDEREÇO</h5>
          <FormField
            name="zipcode"
            label="CEP"
            validation={errors?.zipcode}
            required
          >
            <CleaveField
              name="zipcode"
              type="text"
              inputMode="numeric"
              maxLength={9}
              options={{
                delimiters: ['-'],
                blocks: [5, 3],
                uppercase: true
              }}
              value={user?.zipcode}
              onChange={getCEPDetails}
              placeholder="#####-###"
              disabled={isLoading}
            />
          </FormField>
          <FormField
            name="address"
            label="Rua/Avenida"
            validation={errors?.address}
            required
          >
            <TextField
              name="address"
              type="text"
              autoComplete="street-address"
              value={user?.address}
              onChange={handleChangeInput}
              placeholder="Digite o nome da rua/avenida"
              disabled={isLoading}
            />
          </FormField>
          <FormField
            name="number"
            label="Número"
            validation={errors?.number}
            required
          >
            <TextField
              name="number"
              type="number"
              value={user?.number}
              onChange={handleChangeInput}
              placeholder="Digite o número"
              disabled={isLoading}
            />
          </FormField>
          <FormField
            name="address2"
            label="Complemento"
            validation={errors?.address2}
            required
          >
            <TextField
              name="address2"
              type="text"
              value={user?.address2}
              onChange={handleChangeInput}
              placeholder="Apto, sala, etc."
              disabled={isLoading}
            />
          </FormField>
          <FormField
            name="district"
            label="Bairro"
            validation={errors?.district}
            required
          >
            <TextField
              name="district"
              type="text"
              value={user?.district}
              onChange={handleChangeInput}
              placeholder="Digite o bairro"
              disabled={isLoading}
            />
          </FormField>
          <SelectStateCity
            currentCityOption={user?.currentCityOption}
            currentStateOption={user?.currentStateOption}
            handleCityChange={handleCityChange}
            handleStateChange={handleStateChange}
            stateValidation={errors?.currentState}
            cityValidation={errors?.currentCity}
            isDisabled={isLoading}
          />
          {!isCheckout && <> <h5>PERFIL</h5>
            <FormField
              name="strava"
              label="Perfil Strava"
              required
            >
              <TextField
                prefix={social.strava.prefix}
                name="strava"
                type="text"
                value={user?.socialsNetwork?.find(({ title }) => title === 'strava')?.url}
                onChange={(_, value) => {
                  handleChangeSocial({ socialName: 'strava', url: value })
                }}
                disabled={isLoading}
              />
            </FormField>
            <FormField
              name="instagram"
              label="Perfil Instagram"
              required
            >
              <TextField
                name="instagram"
                type="text"
                prefix={social.instagram.prefix}
                value={user?.socialsNetwork?.find(({ title }) => title === 'instagram')?.url}
                onChange={(_, value) => {
                  handleChangeSocial({ socialName: 'instagram', url: value })
                }}
                disabled={isLoading}
              />
            </FormField>
            <h5>ESPORTE</h5>
            <FormField
              name="currentSports"
              label="Qual seu esporte?"
              validation={errors?.currentSportsOption}
              required>
              <SelectSports
                value={user?.currentSportsOption}
                onChange={handleChangeSports}
                placeholder=""
                isDisabled={isLoading}
              />
            </FormField>
            <FormField name="currentClubs" label="Qual seu clube ou assessoria?">
              <SelectClubs
                isLoading={isLoading}
                isDisabled={(user?.currentCity !== undefined && user?.currentState !== undefined) || isLoading}
                value={user?.currentClubsOption || []}
                city={user?.currentCityOption?.value}
                state={user?.currentStateOption?.value}
                onChange={(currentClubsOption: any) => {
                  setUser({
                    ...user,
                    currentClubsOption
                  })
                }}
              />
            </FormField>
          </>}
          <Button
            variant="primary"
            type="submit"
            margin="40px 0 16px"
            isLoading={isLoading}
          >
            Salvar alterações
          </Button>
          <Button
            margin="0px"
            variant="secondaryNoBorder"
            type="button"
            onClick={handleBtnCancel}
            disabled={isLoading}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ProfileUpdate
