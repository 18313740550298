import React, { useContext, useEffect, useState } from 'react'
import ListingsProvider from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'

import './MyBalance.scss'
import Container from '../../components/Container/Container'
import ReturnHeader from '../../components/ReturnHeader/ReturnHeader'
import { useHistory } from 'react-router-dom'
import { MyBalanceSummary } from './MyBalanceSummary'
import { MyBalanceWithdraw } from './MyBalanceWithdraw'
import { MyBalanceWithdrawSuccess } from './MyBalanceWithdrawSuccess'
import paymentsService from '../../services/paymentsService'
import { toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import MyBalanceLoading from './MyBalanceLoading'
import bankAccountService from '../../services/bankAccountService'
import eqipoService from '../../services/eqipoService'
import { ListingStatus } from '../../models/Listings'
import { GlobalContext } from '../../context/GlobalContext'

interface BalanceProps {
  pendingAmount: number
  currentAmount: number
}

interface HolderProps {
  fullname: string
  taxDocument: {
    number: string
    type: string
  }
}

interface BankAccountProps {
  id: string
  agencyNumber: number
  agencyCheckNumber: number
  accountNumber: number
  accountCheckNumber: number
  bankNumber: number
  bankName: string
  holder: HolderProps
}

const MyBalance = () => {
  const history = useHistory()
  const { userProfile } = useContext(GlobalContext)
  const [isLoading, setIsLoading] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)
  const [balance, setBalance] = useState<BalanceProps>({
    pendingAmount: 0,
    currentAmount: 0
  })
  const [hasSold, setHasSold] = useState(false)
  const [bankAccount, setBankAccount] = useState<BankAccountProps>()

  const verifyAccount = async () => {
    try {
      await paymentsService.getPaymentAccount()
    } catch (error) {
      try {
        await paymentsService.createPaymentAccount()
      } catch (error) {
        // toast.warn(<ToastMessage label="Complete seu cadastro para vender um eqipo"/>)
        history.replace(`/atleta/${userProfile?.username}/editar/checkout?nextScreen=newEqipo`)
      }
    }
  }

  useEffect(
    () => {
      const fetchData = async () => {
        setIsLoading(true)
        try {
          const sales = await eqipoService.getMyEqipos({ itemStatus: [ListingStatus.SOLD] })
          if (sales.length > 0) {
            setHasSold(true)
          }

          await verifyAccount()

          const balance = await paymentsService.getBalance()

          setBalance({
            pendingAmount: Number(balance.pendingAmount / 100),
            currentAmount: Number(balance.currentAmount / 100)
          })

          const responseBank = await bankAccountService.get()
          setBankAccount(responseBank)
        } catch (e: any) {
          const { isWarn, message, error } = e

          console.log(error)

          if (error.response.status === 404) {
            return
          }
          isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
        } finally {
          setIsLoading(false)
        }
      }

      void fetchData()
    },
    []
  )

  return <div>
    <div className="Header">
      <ListingsProvider >
        <Navbar />
      </ListingsProvider>
      <ReturnHeader
        click={() => {
          currentStep === 1 ? history.goBack() : setCurrentStep(currentStep - 1)
        }}
        title={currentStep === 1 ? 'MEU EXTRATO' : 'RESGATAR' }
        border />
    </div>
    <Container className="my-balance">
      {isLoading && <MyBalanceLoading />}

      {!isLoading && currentStep === 1 &&
        <MyBalanceSummary
          setCurrentStep={setCurrentStep}
          balance={balance}
          bankAccount={bankAccount}
          hasSold={hasSold}
        />
      }
      {!isLoading && currentStep === 2 &&
        <MyBalanceWithdraw
          setCurrentStep={setCurrentStep}
          balance={balance}
          setBalance={setBalance}
          bankAccount={bankAccount}
        />
      }
      {!isLoading && currentStep === 3 &&
        <MyBalanceWithdrawSuccess
          setCurrentStep={setCurrentStep}
        />
      }
    </Container>
  </div>
}

export default MyBalance
