import React, { type FC, type ReactNode, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faLock, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import './Accordion.scss'

interface AccordionItemProps {
  title: string
  children: ReactNode
  isOpen: boolean
  isCompleted: boolean
  isLocked?: boolean
  onClick: () => void
}

const AccordionItem: FC<AccordionItemProps> = ({
  title,
  children,
  isOpen,
  isCompleted,
  isLocked,
  onClick
}) => {
  const getIcon = () => {
    if (isCompleted) return faCircleCheck
    if (isLocked) return faLock
    if (isOpen) return faMinus
    return faPlus
  }

  return (
    <div className={`accordion-item ${isOpen ? 'open' : ''} ${isCompleted ? 'completed' : ''} ${isLocked ? 'locked' : ''}`}>
      <div className="accordion-header" onClick={onClick}>
        <div className="accordion-title">
          <div className="accordion-icon">
            <FontAwesomeIcon icon={getIcon()} />
          </div>
          <h3>{title}</h3>
        </div>
        <div className="accordion-status">
          <div className="accordion-status-content">
            <span>{isCompleted ? 'Concluído' : isLocked ? 'Bloqueado' : 'Pendente'}</span>
          </div>
        </div>
      </div>
      <div className="accordion-content">
        {children}
      </div>
    </div>
  )
}

interface AccordionProps {
  kycStatus: boolean
  bankAccountStatus: boolean
  kycContent: ReactNode
  bankAccountContent: ReactNode
}

const Accordion: FC<AccordionProps> = ({
  kycStatus,
  bankAccountStatus,
  kycContent,
  bankAccountContent
}) => {
  const [openSection, setOpenSection] = useState<number>(() => {
    if (bankAccountStatus) return 1 // Open Bank Account section if completed
    if (!kycStatus) return 0 // Open KYC section if not completed
    return -1 // Default to no section open if both are completed
  })

  const handleSectionClick = (index: number) => {
    if (index === 0 && kycStatus) return // Prevent opening KYC if completed
    if (index === 1 && !kycStatus) return // Prevent opening Bank Account if KYC is not completed
    setOpenSection(openSection === index ? -1 : index) // Toggle section open/close
  }

  return (
    <div className="accordion">
      <AccordionItem
        title="Validação de Identidade"
        isOpen={openSection === 0}
        isCompleted={kycStatus}
        onClick={() => {
          handleSectionClick(0)
        }}
      >
        {kycContent}
      </AccordionItem>

      <AccordionItem
        title="Dados Bancários"
        isOpen={openSection === 1}
        isCompleted={bankAccountStatus}
        isLocked={!kycStatus}
        onClick={() => {
          handleSectionClick(1)
        }}
      >
        {bankAccountContent}
      </AccordionItem>
    </div>
  )
}

export default Accordion
